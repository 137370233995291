import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { Link } from "gatsby"

const SuccessPage = ({ location }) => {
  const email = location?.state?.email ? ` at ${location?.state?.email} ` : ` `
  return (
    <Layout isHome={false}>
      <Head title="Success" />
      <h2>Success!</h2>
      <p>
        Thank you. Your form has been submitted. I aim to respond to you at{" "}
        {email} within two working days.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link to="/">Home</Link>
      </div>
    </Layout>
  )
}

export default SuccessPage
